
@import url('https://fonts.googleapis.com/css?family=Vollkorn&display=swap');
.advertiseFirstSection {
    display: flex;
}

.advertiseFirstSectionData {
    width: 40%;
    color: #fafafc;
    background-color: #0f1628;
    text-align: left;
    padding: 4rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.advertiseFirstSectionHeading {
    font-size: 40px;
    font-weight: 500;
    font-family: "Vollkorn",serif;
}

.advertiseFirstSectionDescription {
    font-size: 20px;
    padding-top: 3rem;
}

.advertiseFirstSectionImage {
    width: 60%;
}

.advertiseFirstSectionImage img {
    width: 100%;
    height: 100%;
}

/* second section */

.advertiseSecondSection {
    display: flex;
    padding: 4rem 8rem;
}
.advertiseSecondSectionImage{
    width: 50%;
}
.advertiseSecondSectionImage img{
    max-width: 250px;
    text-align: right;
}
.advertiseSecondSectionData{
    width: 50%;
    padding: 2rem 0;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.advertiseSecondSectionHeading{
    font-size: 40px;
    font-weight: bold;
    font-family: "Vollkorn",serif;
    color: #303030;
}
.advertiseSecondSectionDescription{
    font-size: 20px;
    padding-top: 3rem;
    color: #444444;
}

/* third Section */

.advertiseThirdSection{
    display: flex;
    padding: 4rem 10rem;
    background-color: #0f1628;
    color: white;
}
.advertiseThirdSectionData{
    width: 50%;
    text-align: left;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.advertiseThirdSectionHeading{
    font-size: 40px;
    font-weight: 700;
    font-family: "Vollkorn",serif;
}
.advertiseThirdSectionImage{
    width: 50%;
}
.advertiseThirdSectionImage img{
    max-width: 250px;
}
.advertiseThirdSectionDescription{
    font-size: 20px;
    padding-top: 3rem;
}

/* fourth section */

.advertiseFourthSection {
    display: flex;
    padding: 4rem 8rem;
}
.advertiseFourthSectionImage{
    width: 50%;
}
.advertiseFourthSectionImage img{
    max-width: 250px;
    text-align: right;
}
.advertiseFourthSectionData{
    width: 50%;
    padding: 2rem 0;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.advertiseFourthSectionHeading{
    color: #303030;
    font-size: 40px;
    font-weight: 700;
    font-family: "Vollkorn", serif;
}
.advertiseFourthSectionDescription{
    font-size: 20px;
    padding-top: 3rem;
    color: #444444;
}

/* fifth Section */

.advertiseFifthSection{
    display: flex;
    padding: 4rem 15%;
    background-color: #0f1628;
    color: white;
}
.advertiseFifthSectionData{
    width: 50%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.advertiseFifthSectionHeading{
    font-size: 40px;
    font-weight: 700;
    font-family: "Vollkorn",serif;
}
.advertiseFifthSectionImage{
    width: 50%;
}
.advertiseFifthSectionImage img{
    max-width: 250px;
}
.advertiseFifthSectionDescription{
    font-size: 20px;
    padding-top: 3rem;
}


/* responsive for differect sections */

@media all and (max-width:1200px) {

    .advertiseSecondSection,.advertiseThirdSection,.advertiseFourthSection,.advertiseFifthSection{
        padding: 4rem 2rem;
    }
    
}







@media all and (max-width:500px) {
    .advertiseFirstSection{
        display: flex;
        flex-direction: column-reverse;
        padding: 0;
    }
    .advertiseFirstSectionData,.advertiseFirstSectionImage{
        width: 100%;
    }
    .advertiseFirstSectionData{
        padding: 2rem 1rem;
    }
    .advertiseFirstSectionHeading,.advertiseSecondSectionHeading{
        font-size: 32px;
    }
    .advertiseFirstSectionDescription,.advertiseSecondSectionDescription{
        font-size: 16px;
    }


    /* second section */

    .advertiseSecondSection,.advertiseFourthSection{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0;
        padding: 2rem 0;
    }
    .advertiseSecondSectionImage,.advertiseSecondSectionData,.advertiseFourthSectionImage,.advertiseFourthSectionData{
        width: 100%;
    }
    .advertiseSecondSectionImage{
        display: flex;
        justify-content: center;
        padding: 0;
    }
    .advertiseSecondSectionData{
        padding: 2rem 1rem;
    }
    .advertiseSecondSectionHeading{
        padding: 0;
    }
    .advertiseSecondSectionDescription{
        padding-top: 1rem;
    }

    /* thirdSection */

    .advertiseThirdSection,.advertiseFifthSection{
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        padding: 2rem 1rem;
    }
    .advertiseThirdSectionImage,.advertiseThirdSectionData,.advertiseFifthSectionImage,.advertiseFifthSectionData{
        width: 100%;
    }
    .advertiseThirdSectionHeading,.advertiseFourthSectionHeading,.advertiseFifthSectionHeading{
        font-size: 32px;
        padding: 0;
    }
    .advertiseThirdSectionData,.advertiseFifthSectionData{
        padding: 2rem 0;
    }
    .advertiseThirdSectionDescription,.advertiseFourthSectionDescription,.advertiseFifthSectionDescription{
        font-size: 16px;
        padding-top: 1rem;
    }

    /* fourth section */

    .advertiseFourthSection{
        padding: 2rem 1rem;
    }
}