.digitalSamplingFirstSection {
    display: flex;
}

.digitalSamplingFirstSectionData {
    width: 40%;
    color: #fafafc;
    background-color: #0f1628;
    text-align: left;
    padding: 4rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.digitalSamplingHeading,
.digitalSamplingSecondSectionHeading {
    font-size: 40px;
    font-weight: 500;
    font-family: "Vollkorn", serif;
}

.digitalSamplingFirstSectionDescription {
    font-size: 20px;
    padding-top: 3rem;
}

.digitalSamplingFirstSectionImage {
    width: 60%;
}

.digitalSamplingFirstSectionImage img {
    width: 100%;
    height: 100%;
}



/* second section  */

.digitalSamplingSecondSection {
    padding: 4rem 3rem;
}

.digitalSamplingSecondSectionData {
    display: flex;
    justify-content: space-evenly;
    padding: 2rem 0;
}

.digitalSamplingSecondSectionHeading {
    padding: 0 20rem;
}

.digitalSamplingSecondSectionData img {
    max-width: 250px;
}

.percentHead {
    font-size: 40px;
}

/* thirdSection */

.digitalSamplinThirdSectionImage {
    background-image: url("../Images/digitalSamplingThirdSectionBackgroundImage.webp");
    background-size: cover;
    background-position: center center;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    display: block;
}

.digitalSamplingThirdSection {
    background-color: #0f1628;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.digitalSamplingThirdSectionData {
    padding: 4rem;
    color: white;
    font-size: 26px;
    position: relative;
    z-index: 2;
}

.digitalSamplingThirdSectionData1 {
    padding: 4rem;
    color: white;
    font-size: 26px;
    position: relative;
    z-index: 2;
    padding-top: 0rem;
}

/* fourth section */

.digitalSamplingFourthSection {
    padding: 4rem 8rem;
}

.digitalSamplingFourthSectionHeading {
    font-size: 40px;
    font-weight: 600;
    font-family: "Vollkorn", serif;
    color: black;
}

.digitalSamplingFourthSectionMiddleDataHeading,
.digitalSamplingFourthSectionBottomDataHeading {
    font-size: 26px;
    color: #303030;
    font-family: serif;
    /* padding: 2rem; */
    font-weight: 700;
}

.digitalSamplingFourthSectionMiddleDataDetails,
.digitalSamplingFourthSectionBottomDataDetails {
    font-size: 20px;
    color: #444444;
    padding: 1rem 0;

}

.digitalSamplingFourthSectionMiddle,
.digitalSamplingFourthSectionBottom {
    display: flex;
    padding: 2rem 0;
}

.digitalSamplingFourthSectionMiddleData,
.digitalSamplingFourthSectionMiddleImage,
.digitalSamplingFourthSectionBottomImage,
.digitalSamplingFourthSectionBottomData {
    width: 50%;
    text-align: left;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.digitalSamplingFourthSectionMiddleImage img,
.digitalSamplingFourthSectionBottomImage img {
    width: 100%;

}

/* fifth section */

.digitalSamplingFifthSection {
    padding: 4rem 3rem;
    background-color: #0f1628;
    color: white;
}

.digitalSamplingFifthSectionHeading {
    font-size: 40px;
    font-weight: 700;
    font-family: "Vollkorn", serif;
    padding-bottom: 2rem;
}

.digitalSamplingFifthSectionData {
    font-size: 26px;
}

.digitalSamplingFifthSectionImage img {
    max-width: 1000px;
}

/* sixth section */

.digitalSamplingSixthSection {
    padding: 4rem 10rem;
}

.digitalSamplingSixthSectionHeading {
    font-size: 40px;
    font-weight: 700;
    font-family: "Vollkorn", serif;
    padding-bottom: 2rem;
}

.digitalSamplingSixthSectionMiddle,
.digitalSamplingSixthSectionBottom {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
}

.digitalSamplingSixthSectionMiddle img,
.digitalSamplingSixthSectionBottom img {
    max-width: 100%;
}


/* seventhsection */

.digitalSamplingSeventhSection {
    position: relative;
    background-color: #0f1628;
    color: white;
}

.digitalSamplingSeventhSectionImage {
    background-image: url("../Images/digitalSamplingSeventhSectionBackgroundImage.webp");
    background-size: cover;
    background-position: center center;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    display: block;
}

.digitalSamplingSeventhSectionData {
    color: white;
    font-size: 26px;
    position: relative;
    padding: 5rem 10rem;
    z-index: 2;
}

/* responsive for different width */


@media all and (max-width:1200px) {
    .digitalSamplingFirstSectionData{
        padding: 1rem;
    }
    .digitalSamplingSecondSectionHeading{
        padding: 0 5rem;
    }
    .digitalSamplingFourthSection,.digitalSamplingSixthSection{
        padding: 0;
    }
    .digitalSamplingSeventhSectionData{
        padding: 4rem;
    }
    .digitalSamplingThirdSection{
        padding: 2rem 0;
    }
    .digitalSamplingThirdSectionData,.digitalSamplingThirdSectionData1{
        padding: 2rem; 
    }
    .digitalSamplingThirdSectionData1{
        padding-top: 0;
    }
}
/* responsive for mobile */

@media all and (max-width:500px) {
    .digitalSamplingFirstSection{
        display: flex;
        padding: 0;
        flex-direction: column-reverse;
    }
    .digitalSamplingFirstSectionData,.digitalSamplingFirstSectionImage{
        width: 100%;
    }
    .digitalSamplingFirstSectionData{
        padding: 0;
    }
    .digitalSamplingHeading,.digitalSamplingSecondSectionHeading,.digitalSamplingFourthSectionHeading,.digitalSamplingFifthSectionHeading,.digitalSamplingSixthSectionHeading{
        font-size:32px; 
        padding: 2rem 1rem;
    }
    .digitalSamplingFirstSectionDescription{
        font-size: 16px;
        padding: 0 1rem;
        padding-bottom: 2rem;
    }

    /* second section */

    .digitalSamplingSecondSection,.digitalSamplingThirdSection,.digitalSamplingFourthSection,.digitalSamplingFifthSection,.digitalSamplingSixthSection,.digitalSamplingSeventhSection{
        padding: 0;
    }
    .digitalSamplingSecondSectionData{
        display: flex;
        flex-direction: column;
    }

    /* third section */

    .digitalSamplingThirdSectionData,.digitalSamplingThirdSectionData1{
        font-size: 21px;
        padding: 4rem 1rem;
    }
    .digitalSamplingThirdSectionData1{
        padding-top: 0;
    }

    /* fourth section */

    .digitalSamplingFourthSectionMiddle{
        display: flex;
        flex-direction: column-reverse;
        padding: 0;
    }
    .digitalSamplingFourthSectionBottom{
        display: flex;
        flex-direction: column;
        padding: 0;
    }
    .digitalSamplingFourthSectionMiddleData,.digitalSamplingFourthSectionMiddleImage,.digitalSamplingFourthSectionBottomData,.digitalSamplingFourthSectionBottomImage{
        width: 100%;
        padding: 0;
    }
    .digitalSamplingFourthSectionMiddleImage img{
        padding: 0;
        /* padding-bottom: 2rem; */
    }
    .digitalSamplingFourthSectionBottomDataHeading,.digitalSamplingFourthSectionMiddleDataHeading,.digitalSamplingFifthSectionData{
        font-size: 21px;
        padding: 0 1.5rem;
    }
    .digitalSamplingFourthSectionBottomData,.digitalSamplingFourthSectionMiddleData{
        padding: 2rem 0;
    }
    .digitalSamplingFourthSectionBottomDataDetails,.digitalSamplingFourthSectionMiddleDataDetails{
        font-size: 16px;
        padding:1.5rem ;
    }

    /* fifth section */

    .digitalSamplingFifthSectionImage img{
        width: 100%;
        padding: 1rem 0;
    }

    /* sixth section */
    .digitalSamplingSixthSectionMiddle,.digitalSamplingSixthSectionBottom{
        flex-wrap: wrap;
    }
    .wrapSection{
        max-width: 50%;
    }
    .wrapSection img{
        padding: 1rem;
    }
    .wrapSection div{
        font-size: 16px;
        padding: 1rem;
        padding-top: 0;
    }

    /* seventh section */

    .digitalSamplingSeventhSectionData{
        font-size: 21px;
        padding:3rem 1rem;
    }

}