@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css?family=HelveticaNeue&display=swap');
@import url('https://fonts.googleapis.com/css?family=Helvetica Neue&display=swap');

.App {
  text-align: center;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lato", "HelveticaNeue", "Helvetica Neue", sans-serif;
}

@media  all and (max-width :500px) {
  .App{
    overflow-x: hidden;
  }
  
}