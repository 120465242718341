.footerFirstSection {
    display: flex;
    /* padding: 0rem 10rem; */
    /* justify-content: space-evenly; */
    padding: 1rem 0;
}

.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 1rem;
    /* align-items: center; */
    /* padding: 0 5rem ; */
}

.footer ul {
    list-style: none;
}

.footer li {
    text-transform: uppercase;
    text-align: left;
    color: #4a4a4a;
    font-size: 0.8rem;
    margin: 0.5rem 0;
}

.footerLeftSection {
    display: flex;
    justify-content: space-evenly;
}

.footerLeftSection,
.footerRightSection {
    width: 50%;
}

.footerRightSection form {
    width: 70%;
    padding: 1rem 0;
    border-bottom: 2px solid #4a4a4a;
    display: flex;
    justify-content: space-between;
}

.footerRightSection input {
    border: none;
    /* padding-right: 3rem; */
    width: 80%;
}

.footerRightSection div {
    font-weight: 900;
    color: #4a4a4a;
}

.footerFirstSection button {
    border: none;
    background-color: white;
    cursor: pointer;
    color: #4a4a4a;
}

.footerFirstSection button:hover {
    color: #919599;
}

.copyRight {
    color: #4a4a4a;
    font-size: 80%;
}

.footer li:hover {
    color: #919599;
    cursor: pointer;
}

.footerRightSection {
    text-align: left;
}

.footerSecondSection {
    display: flex;
    padding: 0 3rem;
    justify-content: space-around;
    padding-bottom: 1rem;
}

.footerSocialAccounts * {
    width: 24px;
    height: 24px;
    cursor: pointer;
    padding: 0 5px;
    color: #4a4a4a;
}

.footer ul {
    padding-left: 0;
}

.footerSocialAccounts *:hover {
    color: #919599;
}

.contactButton {
    color: rgb(255, 255, 255) !important;
    background-color: rgb(62, 62, 62) !important;
    font-size: 16px !important;
    border: none;
    border-radius: 5px 5px 0 0;
    padding: 7px 20px !important;
    border: none;
    cursor: pointer;
    position: fixed;
    bottom: 0;
    right: 30px;
    z-index: 3;
}

.contactButton:focus {
    border-color: none;
    box-shadow: none;
}

.modalBody,
.modalHeader {
    display: flex;
    background-color: rgba(10, 10, 10, .75) !important;
    color: white;
}

.modalHeader {
    border: none;
    padding-bottom: 0;
}

.modalHeader .btn-close {
    margin-bottom: -15px;
}

.close {
    color: white !important;
}

.modalBody {
    justify-content: space-evenly;
    padding: 1rem 0 !important;
}

.modalBody form p {
    margin: 0;
    color: red !important;
    opacity: 0.7;
}

.modalBody form input:focus {
    outline-color: white;
}


.modalBody img {
    width: 35%;
    padding-right: 1rem;
}

.modalBody p {
    color: #B2B2B2;
}

.modalBody .contactName,
.companyName,
.messageText {
    width: 100%;
    border-radius: 5px;
    border: none;
    margin: 0.4rem 0;
    padding: 5px 10px;
}

.emailandPhone,
.sizeandCategory {
    width: 100%;
    display: flex;
}

.emailandPhone *,
.sizeandCategory * {
    width: 50%;
    border-radius: 5px;
    border: none;
    margin: 0.4rem 0.1rem;
    padding: 5px 10px;
}

.modalBody button {
    background-color: white;
    color: rgb(62, 62, 62);
    border-radius: 5px;
    padding: 8px 35px;
    border: none;
}

.buttonDiv {
    text-align: right;
}

.homeSmytten {
    color: #4a4a4a;
}

.homeSmytten:hover {
    color: #444444;
}


.contactEnvelope {
    display: none;
}

/* .footer .popUpSection{
    position: absolute;
    z-index: 99999;
    background: rgba(10,10,10,.75);
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
} */


@media all and (max-width:500px) {
    .footer {
        padding: 0;
        text-align: center;
    }

    .footerFirstSection {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .footerLeftSection {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .footerLeftSection ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 0 1rem;
        justify-content: center;
    }

    .footerLeftSection ul li {
        padding: 0 0.5rem;
    }

    .footerRightSection {
        width: 100%;
        padding: 0 1rem;
        text-align: center;
    }

    .footerRightSection form {
        width: 100%;
    }

    .footerSecondSection {
        display: flex;
        flex-direction: column;
    }

    .footerSocialAccounts {
        padding: 1rem 0;
    }

    .footerSocialAccounts * {
        width: 17px;
        height: 17px;
    }

    .contactButton {
        display: none;
    }

    .copyRight {
        padding-bottom: 1rem;
    }

    .contactEnvelope {
        display: block;
        color: rgb(255, 255, 255) !important;
        background-color: rgb(62, 62, 62) !important;
        font-size: 16px !important;
        border: none;
        border-radius: 50%;
        padding: 7px 15px !important;
        border: none;
        cursor: pointer;
        position: fixed;
        bottom: 10px;
        right: 10px;
        z-index: 3;
        width: 50px;
        height: 50px;
    }

    .modalBody img {
        display: none;
    }

    .modalHeader {
        padding: 1rem !important;
        padding-top: 0.5rem !important;
    }

}