@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');

.juryModal .modal-content {
    max-width: 500px;
    margin: auto;
}

.juryPopUpSectionHeader {
    font-size: 30px;
    color: #444444;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}


.juryPopUpSectionHeaderData {
    font-size: 18px;
    color: #444444;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    padding: 10px;
    padding-left: 0;
}

.juryPopUpSection p {
    margin: 0;
}

@media all and (max-width:500px) {
    .juryPopUpSectionHeader {
        font-size: 24px;
    }

    .juryPopUpSectionHeaderData {
        font-size: 16px;
    }

    .juryModal .modal-content {
        max-width: 100%;
    }
}