.header {
    display: flex;
    /* justify-content: space-evenly; */
    align-items: center;
    padding: 1rem 10em;
}

.header img {
    height: 100px;
    margin: 0 auto;
    cursor: pointer;
}

.navBar {
    color: #0f1829;
    cursor: pointer;
    height: 24px;
}

.homeMenu {
    padding: 20px;
    display: flex;
    justify-content: space-around;
    background-color: #fafafc;
}

.homeMenu div {
    padding: 8px 16px;
    font-size: 20px;
    color: #0f1628;
    font-weight: 700;
    cursor: pointer;
}

.navBarHomeMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
}

.navBarHomeMenu div {
    cursor: pointer;
    font-size: 1rem;
    padding: 8px 0px;
    font-weight: 700;
    letter-spacing: .1rem;
    color: #4a4a4a;
    text-transform: uppercase;
}

.navBarHomeMenu div:hover {
    border-bottom: 3px solid #4a4a4a;
}

.socialAccounts * {
    width: 24px;
    height: 24px;
    cursor: pointer;
    padding: 0 5px;
    color: #4a4a4a;
}

.socialAccounts {
    padding: 18px 0;
}

.socialAccounts *:hover {
    color: #919599;
}

/* responsive for mobile */

@media all and (max-width:500px) {
    .header {
        display: flex;
        justify-content: space-between;
        padding: 1em 1.5em;
    }

    .homeMenu {
        display: none;
    }

}