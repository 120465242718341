@import url('https://fonts.googleapis.com/css?family=Vollkorn&display=swap');

.aboutUsSection {
    padding: 0 7rem;
    position: relative;
    margin-top: -5.5rem;
    background-color: white;
}

.aboutUsSectionHeader {
    font-size: 40px;
    font-family: "Vollkorn", serif;
    font-weight: 700;
    color: #4a4a4a;
}

.aboutUsSectionData {
    color: #151414;
    font-size: 23px;
    max-width: 740px;
    margin: 0 auto;
    padding: 2rem 0;
}

.aboutUsSectionFirstImage {
    padding: 0 5rem;
}

.aboutUsSectionFirstImage img {
    width: 100%;
}

/* second section */

.aboutUsSecondSection {
    display: flex;
    padding: 0 2rem;
}

.aboutUsSecondSectionImage,
.aboutUsSecondSectionData {
    width: 50%;
    padding: 4rem 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.aboutUsSecondSectionImage img {
    width: 100%;
}

.aboutUsSecondSectionImage {
    display: flex;
    align-items: center;
    justify-content: center;
}

.aboutUsSecondSectionDataHeader {
    font-size: 26px;
    font-family: "Vollkorn", serif;
    font-weight: 400;
}

.aboutUsSecondSectionDataDescription {
    padding-top: 1rem;
    font-size: 20px;
    color: #151414;
    /* text-align: left; */
}

/* third Section */

.aboutUsThirdSection {
    display: flex;
    padding: 0 2rem;
}

.aboutUsThirdSectionImage,
.aboutUsThirdSectionData {
    width: 50%;
    padding: 4rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.aboutUsThirdSectionImage {
    display: flex;
    align-items: center;
    justify-content: center;
}

.aboutUsThirdSectionImage img {
    width: 100%;
}

.aboutUsThirdSectionDataHeader {
    font-size: 26px;
    /* font-family: serif; */
}

.aboutUsThirdSectionDataDescription {
    padding-top: 1rem;
    font-size: 20px;
    color: #151414;
    text-align: left;
}

/* fifth section */

.aboutUsFifthSection {
    padding: 3rem;
}

.aboutUsFifthSectionHeader {
    font-size: 26px;
    /* font-family: serif; */
}

.aboutUsFifthSection ul li {
    text-align: left;
    padding-top: 0.2rem;
    color: #151414;
}

.aboutUsFifthSection ul {
    margin: 0 20%;
    text-align: left;
    font-size: 20px;
}

.downloadHere {
    color: #151414;
}

.downloadHere:hover {
    color: #919599;
    cursor: pointer;
}

/* sixth section */


.aboutUsSixthSection {
    display: flex;
    padding: 0 2rem;
}

.aboutUsSixthSectionImage,
.aboutUsSixthSectionData {
    width: 50%;
    padding: 2rem;
}

.aboutUsSixthSectionImage {
    display: flex;
    align-items: center;
    justify-content: center;
}

.aboutUsSixthSectionImage img {
    width: 100%;
}

.aboutUsSixthSectionData {
    padding: 10% 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.aboutUsSixthSectionDataHeader {
    font-size: 26px;
    /* font-family: serif; */
}

.aboutUsSixthSectionDataDescription {
    padding-top: 1rem;
    font-size: 22px;
    color: #151414;
    text-align: left;
}

/* seventh section */


.aboutUsSeventhSection {
    display: flex;
    padding: 0 2rem;
}

.aboutUsSeventhSectionImage {
    width: 50%;
    padding: 2rem;
}

.aboutUsSeventhSectionData {
    width: 50%;
    padding: 10% 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.aboutUsSeventhSectionImage img {
    width: 100%;
}

.aboutUsSeventhSectionImage {
    display: flex;
    align-items: center;
    justify-content: center;
}

.aboutUsSeventhSectionDataHeader {
    font-size: 26px;
    /* font-family: serif; */
}

.aboutUsSeventhSectionDataDescription {
    padding-top: 1rem;
    font-size: 20px;
    color: #151414;
    /* text-align: left; */
}

/* eight section */

.aboutUsEightSection {
    margin: 2rem 4rem;
    color: #ffffff;
    background-color: #1c1c1c;
    text-align: center;
    padding: 2rem;
}

.aboutUsEightSectionHeader {
    font-size: 26px;
}

.aboutUsEightSectionData {
    font-size: 20px;
}

.aboutUsSection .footerFirstSection,
.aboutUsSection .footerSecondSection {
    padding: 0;
}

/* .aboutUsSection .footerSecondSection{
    padding: 0 2rem;
} */



@media all and (max-width:1200px) {
    .aboutUsSection{
        padding: 0 5rem;
    }
    .aboutUsFourthSection iframe{
        width: 700px;
        height: 400px;
    }
    
}

@media all and (max-width:500px) {
    .aboutUsSection {
        padding: 0;
        margin: 0;
    }

    .aboutUsSectionHeader {
        font-size: 32px;
        font-weight: 400;
    }

    .aboutUsSectionData {
        font-size: 16px;
        padding: 2rem 1rem;
    }

    .aboutUsSectionFirstImage {
        padding: 0 0.5rem;
        width: 100%;
    }

    .aboutUsSecondSection,
    .aboutUsFifthSection,.aboutUsSeventhSection {
        padding: 2rem 0;
        display: flex;
        flex-direction: column;
    }

    .aboutUsSecondSectionImage,
    .aboutUsSecondSectionData,
    .aboutUsThirdSectionImage,
    .aboutUsThirdSectionData ,.aboutUsSixthSectionData,.aboutUsSixthSectionImage,.aboutUsSeventhSectionData,.aboutUsSeventhSectionImage{
        width: 100%;
        padding: 0 0.5rem;
    }

    .aboutUsSecondSectionData,
    .aboutUsThirdSectionData,.aboutUsSixthSectionData,.aboutUsSeventhSectionData {
        padding: 2rem 1rem;
    }

    .aboutUsSecondSectionDataHeader,
    .aboutUsThirdSectionDataHeader,.aboutUsSixthSectionDataHeader,.aboutUsSeventhSectionDataHeader {
        font-size: 21px;
    }

    .aboutUsSecondSectionDataDescription,
    .aboutUsThirdSectionDataDescription,.aboutUsSixthSectionDataDescription,.aboutUsSeventhSectionDataDescription {
        font-size: 16px;
    }

    /* third section */

    .aboutUsThirdSection,.aboutUsSixthSection {
        padding: 0;
        display: flex;
        flex-direction: column-reverse;
    }

    /* fourthsection */

    .aboutUsFourthSection iframe {
        width: 100%;
        height: 200px;
    }
    .aboutUsFourthSection{
        padding: 0 0.5rem;
    }

    /* fifth section */

    .aboutUsFifthSectionHeader {
        padding: 0;
        font-size: 21px;
        font-family: "Vollkorn", serif;
        font-weight: 400;
    }

    .aboutUsFifthSection ul {
        padding: 0;
        font-size: 16px;
    }

    .aboutUsFifthSection ul li {
        font-size: 16px;
    }

    /* eight section */
    .aboutUsEightSection{
        margin: 0;
        padding: 1.5rem 0.5rem;
        margin-bottom: 2rem;
    }
}