
@import url('https://fonts.googleapis.com/css?family=Vollkorn&display=swap');

.researchFirstSection {
    display: flex;
}

.researchFirstSectionData {
    width: 40%;
    color: #fafafc;
    background-color: #0f1628;
    text-align: left;
    padding: 4rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.researchFirstSectionHeading {
    font-size: 40px;
    font-weight: 700;
    font-family: "Vollkorn",serif;
    
}

.researchFirstSectionDescription {
    font-size: 20px;
    padding-top: 3rem;
}

.researchFirstSectionImage {
    width: 60%;
}

.researchFirstSectionImage img {
    width: 100%;
    height: 100%;
}

/* second section */

.researchSecondSection {
    padding: 4rem 8rem;
}

.researchSecondSectionHeading {
    font-size: 40px;
    font-weight: 700;
    font-family: "Vollkorn",serif;
    color: #303030;
}

.researchSecondSectionHeadingData {
    font-size: 26px;
    padding-top: 1rem;
    color: #444444;
}

.researchSecondSectionMiddle {
    display: flex;
    padding: 2rem 0;
}

.researchSecondSectionMiddleImage {
    width: 50%;
}

.researchSecondSectionMiddleImage img {
    max-width: 300px;
}

.researchSecondSectionMiddleData {
    padding: 3rem;
    font-size: 20px;
    text-align: left;
    color: #444444;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* third section */
.researchThirdSection {
    padding: 4rem 8rem;
    background-color: #0f1628;
    color: white;
}

.researchThirdSectionHeading {
    font-size: 40px;
    font-weight: 700;
    font-family: "Vollkorn",serif;
    /* color: #0f1628; */
}

.researchThirdSectionHeadingData {
    font-size: 26px;
    padding-top: 1rem;
    /* color: #444444; */
}

.researchThirdSectionMiddle {
    display: flex;
    padding: 2rem 0;
}

.researchThirdSectionMiddleImage {
    width: 50%;
}

.researchThirdSectionMiddleImage img {
    max-width: 300px;
}

.researchThirdSectionMiddleData {
    padding: 3rem;
    font-size: 20px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* fourth section */
.researchFourthSection {
    padding: 4rem 8rem;
}

.researchFourthSectionHeading {
    font-size: 40px;
    font-weight: 700;
    font-family: "Vollkorn",serif;
    color: #4a4a4a;
}

.researchFourthSectionHeadingData {
    font-size: 26px;
    padding-top: 1rem;
    color: #444444;
}

.researchFourthSectionMiddle {
    display: flex;
    padding: 2rem 0;
}

.researchFourthSectionMiddleImage {
    width: 50%;
}

.researchFourthSectionMiddleImage img {
    max-width: 300px;
}

.researchFourthSectionMiddleData {
    padding: 3rem;
    font-size: 20px;
    text-align: left;
    color: #444444;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* fifth section */

.researchFifthSection {
    padding: 4rem 8rem;
    background-color: #0f1628;
    color: white;
}

.researchFifthSectionHeading {
    font-size: 40px;
    font-weight: 700;
    font-family: "Vollkorn",serif;
    /* color: #0f1628; */
}

.researchFifthSectionHeadingData {
    font-size: 26px;
    padding-top: 1rem;
    /* color: #444444; */
}

.researchFifthSectionMiddle {
    display: flex;
    padding: 2rem 0;
}

.researchFifthSectionMiddleImage {
    width: 50%;
}

.researchFifthSectionMiddleImage img {
    max-width: 300px;
}

.researchFifthSectionMiddleData {
    padding: 3rem;
    font-size: 20px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* color: #444444; */
}

/* sixth section */

.researchSixthSection {
    padding: 4rem 8rem;
}

.researchSixthSectionHeading {
    font-size: 40px;
    font-weight: 700;
    font-family: "Vollkorn",serif;
    color: #4a4a4a;
}

.researchSixthSectionHeadingData {
    font-size: 26px;
    padding-top: 1rem;
    color: #444444;
}

.researchSixthSectionMiddle {
    display: flex;
    padding: 2rem 0;
}

.researchSixthSectionMiddleImage {
    width: 50%;
}

.researchSixthSectionMiddleImage img {
    max-width: 300px;
}

.researchSixthSectionMiddleData {
    padding: 3rem;
    font-size: 20px;
    text-align: left;
    color: #444444;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* seventh Section */

.researchSeventhSection {
    padding: 4rem 8rem;
    background-color: #0f1628;
    color: white;
}

.researchSeventhSectionHeading {
    font-size: 40px;
    font-weight: 700;
    font-family: "Vollkorn",serif;
    /* color: #0f1628; */
}

.researchSeventhSectionHeadingData {
    font-size: 26px;
    padding-top: 1rem;
    /* color: #444444; */
}

.researchSeventhSectionMiddle {
    display: flex;
    padding: 2rem 0;
}

.researchSeventhSectionMiddleImage {
    width: 50%;
}

.researchSeventhSectionMiddleImage img {
    max-width: 300px;
}

.researchSeventhSectionMiddleData {
    padding: 3rem;
    font-size: 20px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* color: #444444; */
}




@media all and (max-width:1200px) {
    .researchFirstSectionData{
        padding: 2rem 1rem;
    }
    .researchSecondSection,.researchThirdSection,.researchFourthSection,.researchFifthSection,.researchSixthSection,.researchSeventhSection{
        padding: 4rem 2rem;
    }
    .researchSecondSectionMiddleData,.researchFourthSectionMiddleData,.researchSixthSectionMiddleData{
        padding: 2rem;
        padding-right: 0;
    }
    .researchThirdSectionMiddleData,.researchFifthSectionMiddleData,.researchSeventhSectionMiddleData{
        padding: 2rem;
        padding-left: 0;
    }

}


@media all and (max-width:500px) {
    .researchFirstSection{
        display: flex;
        flex-direction: column-reverse;
        padding: 0;
    }
    .researchFirstSectionImage,.researchFirstSectionData{
        width: 100%;
    }
    .researchFirstSectionHeading{
        font-size: 32px;
        padding: 0;
    }
    .researchFirstSectionDescription{
        font-size: 16px;
        padding: 0;
        padding-top: 1rem;
    }
    .researchFirstSectionData{
        padding: 2rem 1.5rem;
    }

    /* second section */

    .researchSecondSection,.researchThirdSection,.researchFourthSection,.researchFifthSection,.researchSixthSection,.researchSeventhSection{
        display: flex;
        flex-direction: column;
        padding: 0;
    }
    .researchSecondSectionHeading,.researchThirdSectionHeading,.researchFourthSectionHeading,.researchFifthSectionHeading,.researchSixthSectionHeading,.researchSeventhSectionHeading{
        font-size: 32px;
        padding: 0;
        padding-top: 2rem;
    }
    .researchSecondSectionHeadingData,.researchThirdSectionHeadingData,.researchFourthSectionHeadingData,.researchFifthSectionHeadingData,.researchSixthSectionHeadingData,.researchSeventhSectionHeadingData{
        font-size: 21px;
        padding: 2rem 1rem;
    }
    .researchSecondSectionMiddle{
        display: flex;
        flex-direction: column;
        justify-content: center;
        
    }
    .researchSecondSectionMiddleData,.researchThirdSectionMiddleData,.researchFourthSectionMiddleData,.researchFifthSectionMiddleData,.researchSixthSectionMiddleData,.researchSeventhSectionMiddleData{
        display: flex;
        flex-direction: column;
        padding: 2rem 1rem;
        font-size: 16px;
    }
    .researchSecondSectionMiddleImage,.researchThirdSectionMiddleImage,.researchFourthSectionMiddleImage,.researchFifthSectionMiddleImage,.researchSixthSectionMiddleImage,.researchSeventhSectionMiddleImage{
        width: 100%;
    }

    /* third section */

    .researchThirdSectionMiddle,.researchFifthSectionMiddle,.researchSeventhSectionMiddle{
        display: flex;
        flex-direction: column-reverse;
        padding: 0;
        justify-content: center;
    }

    /* fourth section */

    .researchFourthSectionMiddle,.researchSixthSectionMiddle{
        display:flex;
        flex-direction: column;
        justify-content: center;
    }
}