@import url('https://fonts.googleapis.com/css?family=Vollkorn&display=swap');

.successStoriesFirstSection {
    display: flex;
}

.successStoriesFirstSectionData {
    width: 40%;
    color: #fafafc;
    background-color: #0f1628;
    text-align: left;
    padding: 4rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.successStoriesFirstSectionHeading {
    font-size: 40px;
    font-weight: 700;
    font-family: "Vollkorn", serif;
}

.successStoriesFirstSectionDescription {
    font-size: 20px;
    padding-top: 3rem;
}

.successStoriesFirstSectionImage {
    width: 60%;
}

.successStoriesFirstSectionImage img {
    width: 100%;
    height: 100%;
}

/* second section */

.successStoriesSecondSection {
    padding: 4rem 8rem;
}

.successStoriesSecondSectionHeading {
    font-size: 40px;
    font-weight: 700;
    font-family: "Vollkorn", serif;
    color: #4a4a4a;
}

.successStoriesSecondSectionHeadingData {
    font-size: 26px;
    /* padding-top: 1rem; */
    color: #444444;
}

.successStoriesSecondSectionMiddle {
    display: flex;
    padding: 2rem 0;
}

.successStoriesSecondSectionMiddleImage {
    width: 50%;
}

.successStoriesSecondSectionMiddleImage img {
    max-width: 500px;
}

.successStoriesSecondSectionMiddleData {
    padding: 1rem 3rem;
    font-size: 20px;
    text-align: left;
    color: #444444;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.successStoriesSecondSection li {
    padding-bottom: 1rem;
}

/* third section */

.successStoriesThirdSection {
    background-color: #0f1628;
    color: white;
    padding: 4rem 8rem;
}

.successStoriesThirdSectionHeading {
    font-size: 40px;
    font-weight: 700;
    font-family: "Vollkorn", serif;
}

.successStoriesThirdSectionHeadingData {
    font-size: 26px;
}

.successStoriesThirdSectionMiddle {
    display: flex;
    padding: 2rem 0;

}

.successStoriesThirdSectionMiddleImage {
    width: 50%;
}

.successStoriesThirdSectionMiddleImage img {
    max-width: 500px;
}

.successStoriesThirdSectionMiddleData {
    padding: 3rem;
    font-size: 20px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* color: #444444; */
}

.successStoriesThirdSection li {
    padding-bottom: 1rem;
}

/* fourth section */

.successStoriesFourthSection {
    padding: 4rem 8rem;
}

.successStoriesFourthSectionHeading {
    font-size: 40px;
    font-weight: 700;
    font-family: "Vollkorn", serif;
    color: #4a4a4a;
}

.successStoriesFourthSectionHeadingData {
    font-size: 26px;
    /* padding-top: 1rem; */
    color: #444444;
}

.successStoriesFourthSectionMiddle {
    display: flex;
    padding: 2rem 0;
}

.successStoriesFourthSectionMiddleImage {
    width: 50%;
}

.successStoriesFourthSectionMiddleImage img {
    max-width: 500px;
}

.successStoriesFourthSectionMiddleData {
    padding: 2rem 3rem;
    font-size: 20px;
    text-align: left;
    color: #444444;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.successStoriesFourthSection li {
    padding-bottom: 1rem;
}

/* fifth section */


.successStoriesFifthSection {
    background-color: #0f1628;
    color: white;
    padding: 4rem 8rem;
}

.successStoriesFifthSectionHeading {
    font-size: 40px;
    font-weight: 700;
    font-family: "Vollkorn", serif;
}

.successStoriesFifthSectionHeadingData {
    font-size: 26px;
}

.successStoriesFifthSectionMiddle {
    display: flex;
    padding: 2rem 0;
}

.successStoriesFifthSectionMiddleImage {
    width: 50%;
}

.successStoriesFifthSectionMiddleImage img {
    max-width: 500px;
}

.successStoriesFifthSectionMiddleData {
    padding: 3rem;
    font-size: 20px;
    text-align: left;
    /* color: #444444; */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.successStoriesFifthSection li {
    padding-bottom: 1rem;
}


@media all and (max-width:1200px) {

    .successStoriesSecondSection,
    .successStoriesThirdSection,
    .successStoriesFourthSection,
    .successStoriesFifthSection {
        padding: 3rem 2rem;
    }

    .successStoriesSecondSectionMiddleData,
    .successStoriesThirdSectionMiddleData,
    .successStoriesFourthSectionMiddleData,
    .successStoriesFifthSectionMiddleData {
        padding: 1rem;
    }
}

@media all and (max-width:500px) {

    .successStoriesFirstSection,
    .successStoriesThirdSectionMiddle,
    .successStoriesFifthSectionMiddle {
        display: flex;
        padding: 0;
        flex-direction: column-reverse;
    }

    .successStoriesFirstSectionImage,
    .successStoriesFirstSectionData,
    .successStoriesSecondSectionMiddleImage,
    .successStoriesSecondSectionMiddleData,
    .successStoriesFifthSectionMiddleImage,
    .successStoriesFifthSectionMiddleData {
        width: 100%;
    }

    .successStoriesFirstSectionData {
        padding: 2rem 1rem;
    }

    .successStoriesFirstSectionHeading,
    .successStoriesThirdSectionHeading {
        font-size: 32px;
    }

    .successStoriesFirstSectionDescription {
        font-size: 16px;
    }

    /* second section */

    .successStoriesSecondSection,
    .successStoriesThirdSection,
    .successStoriesFourthSection {
        flex-direction: column;
        padding: 0;
    }

    .successStoriesSecondSectionHeadingData,
    .successStoriesThirdSectionHeadingData,
    .successStoriesFourthSectionHeadingData {
        font-size: 21px;
        padding-bottom: 2rem;
    }

    .successStoriesSecondSectionHeading,
    .successStoriesFourthSectionHeading {
        font-size: 32px;
        padding: 2rem 1rem;
        color: black;
    }

    .successStoriesSecondSectionMiddle,
    .successStoriesFourthSectionMiddle {
        flex-direction: column;
        padding: 0;
    }

    .successStoriesSecondSectionMiddleImage img,
    .successStoriesThirdSectionMiddleImage img,
    .successStoriesFourthSectionMiddleImage img,
    .successStoriesFifthSectionMiddleImage img {
        width: 100%;
    }

    .successStoriesSecondSectionMiddleData,
    .successStoriesThirdSectionMiddleData,
    .successStoriesFourthSectionMiddleData,
    .successStoriesFifthSectionMiddleData {
        font-size: 16px;
        padding: 2rem 1rem;
    }

    /* third section */

    .successStoriesThirdSectionMiddleImage,
    .successStoriesThirdSectionMiddleData,
    .successStoriesFourthSectionMiddleImage {
        width: 100%;
        /* padding: 0; */
    }

    .successStoriesThirdSectionHeading {
        padding: 2rem 0;
    }

    .successStoriesThirdSectionHeadingData {
        padding: 0 1rem;
        padding-bottom: 2rem;
    }


    /* fifthSection */

    .successStoriesFifthSection {
        padding: 0;
    }

    .successStoriesFifthSectionHeading {
        font-size: 32px;
        padding: 2rem 1rem;
    }

    .successStoriesFifthSectionHeadingData {
        font-size: 21px;
        padding: 0;
        padding-bottom: 2rem;
    }

}