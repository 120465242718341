
@import url('https://fonts.googleapis.com/css?family=Vollkorn&display=swap');
.privacyPolicySection{
    padding: 0 6rem;
    position: relative;
    margin-top: -5.5rem;
    background-color: white;
    /* font-family: Helvetica ,sans-serif;; */
}
.privacyPolicySectionHeader{
    font-size: 40px;
    font-family: "Vollkorn", serif;
    font-weight: 700;
    color: #4a4a4a;
}
.privacyPolicySectionDataHeader{
    font-size: 20px;
    color: #4a4a4a;
    padding-top: 2rem;
    font-weight: 700;
}
.privacyPolicySectionDataDescription{
    font-size: 18px;
    color: #4a4a4a;
    text-align: left;
    padding: 2rem 5rem;
    line-height: 1.4;
    text-align: left;
}
.privacyPolicySectionDataDescription a{
    color: #4a4a4a;
}
.privacyPolicySectionDataDescription a:hover{
    color: #151414;
}
.privacyPolicySection .footerFirstSection,.privacyPolicySection .footerSecondSection{
    padding: 0;
}
.privacyPolicySection .footerSecondSection{
    padding: 0 2rem;
}


@media all and (max-width:500px) {

    .privacyPolicySection{
        padding: 0;
        margin: 0;
    }
    .privacyPolicySectionHeader{
        padding: 0;
        font-size: 32px;
        font-weight: 400;
    }
    .privacyPolicySectionDataHeader{
        font-size: 17px;
        padding: 0 1rem;
        padding-top: 1rem;
    }
    .privacyPolicySectionDataDescription{
        font-size: 16px;
        padding: 2rem 1rem;
    }
    
}