@import url('https://fonts.googleapis.com/css?family=Inter&display=swap');

/* .submitModal .modal-content {
    max-width: 500px;
    margin: auto;
}

.submitModalSection {
    font-size: 18px;
    color: #444444;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    padding: 10px;
    padding-left: 0;
    text-align: center;
}
.submitModalSectionData{
    display: flex;
    padding: 5px 0;
}
.submitModalSection p{
    margin: 0;
    padding: 5px;
    font-size: 13px;
}

.submitModalSectionData input{
    width: 40px;
    cursor: pointer;
}

.submitModalSection button{
    color: white;
    background-color: #283464;
    padding: 10px 30px;
    border-radius: 2rem;
    border: none;
    cursor: pointer;
    margin: 0;
} */


.agreeModalSection{
    text-align: center;
    font-family: 'Inter';
}
.agreeModalSectionHeader{
    font-size: 24px;
    font-weight: 600;
    padding: 1rem;
}

.completingDetails{
    color: #7D7C80;
    font-size: 16px;
    font-weight: 400;
    padding: 1rem;
}

.completingDetails span{
    color: black;
    font-weight: 500;
}
.continueText{
    font-size: 18px;
    font-weight: 600;
    
}

.agreeButtons{
    display: flex;
    justify-content: center;
    padding:1rem ;
}

.goBackButton{
    width: 150px;
    padding: 1rem ;
    color: #283464;
    font-size: 16px;
    font-weight: 500;
    border: none;
    border-radius: 4px;
    margin: 0 1rem;
}
.yesIAgreeButton{
    width: 150px;
    padding: 1rem;
    background-color: #283464;
    color: white;
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 4px;

}