@import url('https://fonts.googleapis.com/css?family=Vollkorn&display=swap');

.brandConnectSection {
    padding: 4rem 10rem;
    background-color: white;
    position: relative;
    padding-top: 0;
    margin-top: -5.5rem;
}


.brandConnectSectionHeader {
    color: #4a4a4a;
    font-size: 40px;
    font-family: "Vollkorn", serif;
    font-weight: 500;
}

.brandConnectSectionData {
    padding: 2rem 11rem;
    font-size: 19px;
    color: #4a4a4a;
}

.brandConnectContactDetails {
    padding: 0rem 11rem;
}

.brandConnectContactName,
.brandConnectCompanyName,
.brandConnectMessageText {
    width: 100%;
    /* border: none;
     */
     border: 1px solid #c4c4c4;
     margin: 0.4rem 0;
    padding: 5px 10px;
}

.brandConnectEmailandPhone,
.brandConnectSizeandCategory {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.4rem 0;
    padding: 5px 0px;
}

.brandConnectEmailandPhone *,
.brandConnectSizeandCategory * {
    width: 48%;
    /* border: none; */
    border: 1px solid #c4c4c4;
    padding: 5px 10px;
}

.brandConnectButtonSection {
    text-align: left;
}

.brandConnectButton {
    background-color: black;
    color: white;
    padding: 10px 30px;
    border: none;
    cursor: pointer;
}


.brandConnectMainSection .footerFirstSection,
.brandConnectMainSection .footerSecondSection {
    padding: 0;
}

.brandConnectMainSection .footerSecondSection {
    padding-left: 3rem;
    padding-bottom: 2rem;
}

::-webkit-input-placeholder {
    /* Edge */
    color: lightgray;
}

:-ms-input-placeholder {
    /* Internet Explorer */
    color: lightgray;
}

::placeholder {
    color: lightgray;
}

.brandConnectContactDetails{
    text-align: left;
}
.brandConnectContactDetails input,.brandConnectContactDetails textarea{
    border-radius: 4px;
}
.brandConnectContactDetails p{
    margin: 0;
    color: red !important;
    opacity: 0.7;
}


@media all and (max-width:1200px) {
    .brandConnectSection {
        padding: 3rem;
    }

}

@media all and (max-width:500px) {
    .brandConnectSection {
        padding: 0;
        margin: 0;
    }

    .brandConnectSectionHeader {
        padding: 2rem 0;
        font-size: 32px;
        font-weight: 400;
    }

    .brandConnectSectionData {
        padding: 2rem 1rem;
        font-size: 17px;
        padding-top: 0;
    }

    .brandConnectContactDetails {
        padding: 2rem 1rem;
    }

    .brandConnectEmailandPhone,
    .brandConnectSizeandCategory {
        display: flex;
        flex-direction: column;
        margin: 0;
    }

    .brandConnectEmailandPhone input,
    .brandConnectSizeandCategory input {
        width: 100%;
        margin: 0.4rem 0;
        ;
    }
}