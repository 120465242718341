
@import url('https://fonts.googleapis.com/css?family=Vollkorn&display=swap');

.contactUsSection{
    padding: 0 7rem;
    position: relative;
    margin-top: -5.5rem;
    background-color: white;
}
.contactUsSectionHeader{
    font-size: 40px;
    font-family: "Vollkorn", serif;
    font-weight: 700;
    color: #4a4a4a;
}
.contactUsSectionData{
    padding: 2rem 6rem;
    font-size: 20px;
    color: #4a4a44;
}

.contactUsSection .footerFirstSection,.contactUsSection .footerSecondSection{
    padding: 0;
}
.contactUsSection .footer{
    padding-top: 3rem;
}
/* .contactUsSection .footerSecondSection{
    padding: 0 2rem;
} */


@media all and (max-width:500px) {
    .contactUsSection{
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .contactUsSectionHeader{
        font-size: 32px;
        font-weight: 400;
        padding: 0;
    }
    .contactUsSectionData{
        padding: 0;
        font-size: 17px;
        padding: 2rem 1rem;
        text-align: left;
    }
}