@import url('https://fonts.googleapis.com/css?family=Vollkorn&display=swap');


.notFoundSection{
    position: relative;
    margin-top: -5.5rem;
    background-color: white;
}
.notFoundSectionHeader{
    font-size: 42px;
    font-family: "Vollkorn",serif;
    color: #4a4a4a;
    padding: 10px;
}
.notFoundSection p{
    padding: 5px;
    margin: 0;
}
.notFoundSection button{
    border: none;
    background-color: black;
    color: white;
    padding: 15px 30px;
}


@media all and (max-width:500px){
    .notFoundSection {
        margin: 0;
    }
}