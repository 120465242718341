@import url('https://fonts.googleapis.com/css?family=Vollkorn&display=swap');

.mainPageFirstSection {
    background-color: #0f1628;
    position: relative;
    color: white;
}

.trySomeThingNewSection {
    padding: 5rem 4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: white;
    position: relative;
    z-index: 2;
}

.trySomeThingNewSectionImage {
    background-image: url("../../Images/trySomethingNewBackground.webp");
    background-size: cover;
    background-position: center center;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0.25;
    display: block;
}

.trySomeThingNew {
    font-size: 40px;
    color: white;
    padding: 30px 0;
    position: relative;
    color: white;
    font-family: "Vollkorn", serif;
    font-weight: 700;
}

.trySomeThingNewDescription {
    font-size: 26px;
    color: white;
    padding: 30px 0;
    position: relative;
}

.mainPageFirstSection button {
    color: #0f1628;
    background-color: #ffffff;
    border-radius: 1px;
    padding: 8px 16px;
    border: none;
    font-weight: 700;
    font-size: 20px;
    margin: 30px 0;
    cursor: pointer;
    position: relative;
    font-family: "Lato", "HelveticaNeue", "Helvetica Neue", sans-serif;
}


/* center section */
.mainPageSecondSection {
    padding: 3rem 2rem;
}

.digitalProductSampledHeader {
    font-size: 40px;
    font-family: "Vollkorn", serif;
    padding: 30px;
    font-weight: 700;
}

.digitalProductSampledCenter {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 30px;
}

.digitalProductSampledCenter img {
    max-width: 100%;
}

.digitalSampledImageSection,
.digitalSampleddData {
    width: 50%;
    padding: 0 2rem;
}

.digitalSampleddData {
    text-align: left;
}

.digitalSampleddData div {
    font-size: 20px;
    text-align: left;
    color: #444444;
    padding: 0 2rem;
    padding-bottom: 2rem;
    font-family: "Lato", "HelveticaNeue", "Helvetica Neue", sans-serif;
}

.digitalSampleddData button {
    border: none;
    background-color: black;
    color: white;
    padding: 8px 16px;
    cursor: pointer;
    margin: 0 2rem;
    font-size: 16px;
}

/* thirdSection */

.companyBrands {
    font-size: 40px;
    padding: 1rem;
    font-size: 40px;
    font-family: "Vollkorn", serif;
    font-weight: 700;
    /* color: black; */
}

.aboutCompany {
    font-size: 26px;
    padding: 3rem 11rem;
}

.mainPageThirdSection {
    background-color: #0f1628;
    color: white;
    padding: 3rem 6rem;
}

.sliderSection {
    width: 100%;
}

.slideCarousel {
    width: 100%;
}

.carousel-indicators [data-bs-target] {
    background-color: white !important;
}

.mainPageSection .carousel-control-prev,
.mainPageSection .carousel-control-next {
    display: none !important;
}



/* fourthSection */

.mainPageFourthSection {
    padding: 3rem 5rem;
}

.exposureImage,
.exposureData {
    padding: 1rem;
    width: 50%;
}

.exposureImage img {
    max-width: 100%;
}

.exposureMiddleSection {
    display: flex;
    justify-content: space-between;
}

.exposureHeading {
    font-size: 40px;
    padding: 1rem;
    font-family: "Vollkorn", serif;
    font-weight: 700;
}

.exposureData {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.exposureData div {
    font-size: 20px;
    color: #444444;
    padding: 2rem;
}

.exposureData button {
    background-color: black;
    color: white;
    border: none;
    cursor: pointer;
    padding: 8px 16px;
    font-size: 16px;
    margin: 2rem;
}


/* fifth Section */
.mainPageFifthSection {
    background-color: #0f1628;
    color: white;
    padding: 4rem 3rem;
}

.researchHeading {
    font-size: 40px;
    padding-bottom: 3rem;
    font-family: "Vollkorn", serif;
    font-weight: 700;
}

.researchMiddleSection {
    display: flex;
    justify-content: space-between;
}

.researchData,
.researchImage {
    padding: 1rem;
    width: 50%;
}

.researchData {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.researchData div {
    font-size: 20px;
    padding: 2rem;
}

.researchData button {
    color: #0f1628;
    background-color: #ffffff;
    padding: 8px 16px;
    cursor: pointer;
    border: none;
    margin: 2rem;
    font-size: 16px;
}

.researchImage img {
    width: 100%;
}

/* sixth section */

.mainPageSixthSection {
    padding: 4rem 3rem;
}

.successHeading {
    font-size: 40px;
    padding-bottom: 3rem;
    font-family: "Vollkorn", serif;
    font-weight: 700;
}

.successStoriesMiddleSection {
    display: flex;
    justify-content: space-between;
}

.successImage,
.successStoriesData {
    padding: 1rem;
    width: 50%;
}

.successImage img {
    width: 100%;
}

.successStoriesData {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.successStoriesData div {
    font-size: 20px;
    text-align: left;
    color: #444444;
    padding: 2rem;
}

.successStoriesData button {
    background-color: #0f1628;
    color: #ffffff;
    padding: 8px 16px;
    cursor: pointer;
    border: none;
    margin: 2rem;
    font-size: 16px;
}

/* seventh section */

.mainPageSeventhSection {
    padding: 4rem 3rem;
    background-color: #fafafc;
}

.brandsTalkHeading {
    font-size: 40px;
    padding-bottom: 3rem;
    font-family: "Vollkorn", serif;
    font-weight: 700;
}

.sliderSectionBrandsTalk {
    padding: 3rem 12rem;
}

.carousel-indicators [data-bs-target] {
    max-width: 9px;
    min-height: 9px;
    border-radius: 80%;
    background-color: #C7D3DD;
}

/* eightSection */

.mainPageEighthSection {
    padding: 4rem 3rem;
    background-color: #efebe2;
}

.interestedHeading {
    padding-bottom: 1rem;
    font-size: 40px;
    font-weight: 700;
    font-family: "Vollkorn", serif;
    color: #444444;
}

.interestedData {
    padding-bottom: 1rem;
    font-size: 26px;
    color: #151414;
}

.mainPageEighthSection button {
    margin-bottom: 1rem;
    color: #ffffff;
    background-color: #303030;
    padding: 8px 16px;
    border: none;
    cursor: pointer;
}
.mobileMain{
    display: none;
}
.carousel-control-prev, .carousel-control-next,.carousel-indicators{
    z-index: 3 !important;
}
/* footer */


@media all and (max-width:1200px) {
    .mainPageSecondSection,.mainPageFourthSection,.mainPageFifthSection,.mainPageSixthSection,.mainPageSeventhSection{
        padding: 2rem 0;
    }
    .digitalProductSampledHeader{
        padding: 0;
    }
    .digitalProductSampledCenter{
        padding: 30px 0;
    }
    .sliderSectionBrandsTalk{
        padding: 2rem 6rem;
        padding-top: 0;
    }
    .trySomeThingNewSection{
        padding: 2rem 4rem;
    }
    
}
/* responsive mobile view */

@media all and (max-width:500px) {
    .mobileHide{
        display: none;
    }
    .mobileMain{
        display: block;
    }
    .mobileMainImage {
        width: 100%;
    }
    .mobileMainImage img{
        width: 100%;
    }
    .trySomeThingNewSectionImage {
        background-repeat: no-repeat;
        background-position: 95% center;
        opacity: 0.2;
    }

    .trySomeThingNewSection {
        padding: 2rem;
    }

    .trySomeThingNew {
        font-size: 32px;
    }

    .trySomeThingNewDescription {
        font-size: 21px;
    }

    .mainPageFirstSection button {
        font-size: 13px;
    }



    /* second section */


    .mainPageSecondSection {
        padding: 0;
    }

    .digitalProductSampledHeader {
        font-size: 32px;
        padding: 2rem 0;
    }

    .digitalProductSampledCenter {
        display: flex;
        flex-direction: column-reverse;
        padding: 0;
    }

    .digitalSampleddData,
    .digitalSampledImageSection {
        width: 100%;
        padding: 0;
        padding-bottom: 2rem;
    }

    .digitalSampleddData div {
        font-size: 16px;
    }

    .digitalSampledImageSection img {
        max-width: 100%;
    }

    .digitalSampleddData button {
        font-size: 13px;
    }


    /* third section */

    .mainPageThirdSection {
        padding: 0;
    }

    .companyBrands {
        font-size: 32px;
        padding: 2rem;
    }

    .aboutCompany {
        padding: 1rem;
        font-size: 21px;
        padding-bottom: 2rem;
    }

    .mainPageThirdSection .carousel-indicators {
        position: relative !important;
    }

    .mainPageThirdSection .carousel-dark {
        display: flex;
        flex-direction: column-reverse;
        padding: 0 1rem;
        padding-bottom: 2rem;
    }

    /* fourthSection */

    .mainPageFourthSection,
    .mainPageFifthSection,
    .mainPageSixthSection,
    .mainPageSeventhSection,
    .mainPageEighthSection {
        padding: 0;
    }

    .exposureHeading {
        font-size: 32px;
    }

    .exposureMiddleSection {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .exposureImage,
    .exposureData {
        width: 100%;
    }

    .exposureImage {
        padding: 0;
    }

    .exposureData div {
        font-size: 16px;
    }

    .exposureData button {
        font-size: 13px;
    }

    /* fifthSection */

    .researchHeading {
        font-size: 32px;
        padding: 2rem;
    }

    .researchMiddleSection {
        display: flex;
        flex-direction: column-reverse;
    }

    .researchImage,
    .researchData {
        width: 100%;
        padding: 0;
    }

    .researchData div {
        font-size: 16px;
    }

    .researchData button {
        font-size: 13px;
    }

    /* sixth Section */

    .successHeading {
        font-size: 32px;
        padding: 2rem;
    }

    .successStoriesMiddleSection {
        display: flex;
        flex-direction: column;
    }

    .successImage,
    .successStoriesData {
        width: 100%;
        padding: 0;
    }

    .successStoriesData div {
        font-size: 16px;
    }

    .successStoriesData button {
        font-size: 13px;
    }

    /* seventh section */

    .brandsTalkHeading {
        font-size: 32px;
        padding: 2rem;
    }

    .sliderSectionBrandsTalk {
        padding: 0;
    }

    .mainPageSeventhSection .carousel-indicators {
        position: relative !important;
    }

    .mainPageSeventhSection .carousel-indicators [data-bs-target] {
        background-color: #444444 !important;
    }

    .mainPageSeventhSection .carousel {
        display: flex;
        flex-direction: column-reverse;
        padding: 0 1rem;
    }

    /* eight section */

    .interestedHeading {
        font-size: 32px;
        padding: 2rem;
    }

    .interestedData {
        font-size: 21px;
        padding: 0 2rem;
        padding-bottom: 2rem;
    }

    .mainPageEighthSection button {
        font-size: 13px;
    }
}