@import url('https://fonts.googleapis.com/css?family=Italic&display=swap');


/* slider images */
.forbesSectionSlider img {
    width: 100%;
}

/* faq section */
.faqDropDown{
    text-align: left;
    padding: 1rem 20%;
}
.boldFaqQuestion,.faqHeader{
    font-weight: 700;
    font-family: sans-serif;
}
.faqHeader{
    padding: 2rem;
    text-align: center;
}


.faqDropDownBox{
    margin: 2rem 20%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
}
.faqDropDownBox div{
    font-size: 32px;
    font-weight: 700;
}
.faqDropDownBox svg{
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.nominationForm{
    background-color: white;
    padding-bottom: 2rem;
}
.nominationSectionHeaderData span{
    font-family: 'Italic';
    font-style: italic;
}

.nominationForm input{
    border-radius: 4px;
    border: 1px solid #C4C4C4;
}
/* open all industires section */
.openAllIndustriesSection {
    background-color: #f2f2f2;
    padding: 2rem;
}

.openAllIndustriesSectionHeader {
    font-size: 20px;
    font-weight: 700;
    font-family: sans-serif;
}

.openAllIndustriesFirstSection {
    margin: 1rem 10%;
    padding: 1rem 2rem;
    background-color: white;
}

.openAllIndustriesFirstSectionHeader {
    font-size: 18px;
    font-weight: 700;
    font-family: sans-serif;
    padding-bottom: 1rem;
}

.openAllIndustriesFirstSection p {
    margin: 0;
    padding: 0;
}

/* nomination section */
.nominationSection {
    background-color: rgba(209, 216, 238, 255);
    padding: 2rem 0rem;
}

.nominationHeader{
    padding: 2rem 20%;
    padding-top: 0;
    text-align: left;
}


.nominationSectionHeader {
    font-size: 20px;
    font-weight: 700;
    font-family: sans-serif;
}



/* company information section  and contact details of applicant section*/
.nominationCompanyInformationSection,
.nominationContactDetailsApplicantSection,
.nominationCaseStudySection,
.nominationSocialMediaSection,
.nominationAttachDocumentsSection,
.nominationAdditionlDocumentsSection,
.nominatedProduct {
    text-align: left;
    padding: 2rem 20%;
}

.nominationContactDetailsApplicantSection,
.nominationCaseStudySection,
.nominationSocialMediaSection,
.nominationAttachDocumentsSection,
.nominationAdditionlDocumentsSection {
    padding-top: 0;
}

.nominationCompanyInformationSectionHeader,
.nominationContactDetailsApplicantSectionHeader,
.nominationCaseStudySectionHeader,
.nominationSocialMediaSectionHeader,
.nominationAttachDocumentsSectionHeader,
.nominatedProductHeader {
    font-size: 18px;
    font-weight: 700;
    font-family: sans-serif;
}

.nominationErrorMessage {
    color: red;
    opacity: 0.6;
}

/* name of oraganization  and corporate office section and state and date section and webiste contact section 
and contact details section and contactid and email section and summarize section case study */

.nameOfOrganizationSection,
.corporateOfficeAddressSection,
.stateAndDateSection,
.websiteAndContactSection,
.contactNameAndPositionSection,
.contactIdAndEmailSection {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
}

.nameOfOrganizationFirstSection,
.nameOfOrganizationSecondSection,
.corporateOfficeAddressFirstSection,
.corporateOfficeAddressSecondSection,
.stateAndDateSecondSection,
.stateAndDateFirstSection,
.websiteAndContactFirstSection,
.websiteAndContactSecondSection,
.contactNameAndPositionFirstSection,
.contactNameAndPositionSecondSection,
.contactIdAndEmailFirstSection,
.contactIdAndEmailSecondSection {
    width: 40%;
}

.nameOfOrganizationSection p,
.corporateOfficeAddressSection p,
.stateAndDateSection p,
.websiteAndContactSection p,
.contactNameAndPositionSection p,
.contactIdAndEmailSection p,
.caseStudySummarizeSection p,
.caseStudySummarizeFirstSection p,
.caseStudySummarizeSecondSection p,
.caseStudySummarizeThirdSection p {
    color: #4a4a4a;
    margin: 0;
    margin-bottom: 5px;
}

.nameOfOrganizationSection input,
.corporateOfficeAddressSection input,
.stateAndDateSection input,
.websiteAndContactSection input,
.contactNameAndPositionSection input,
.contactIdAndEmailSection input,
.thirdSectionInput {
    width: 100%;
    padding: 10px;
    /* border: none; */
}

.stateAndDateSection select {
    width: 100%;
    padding: 10px;
    border: 1px solid #C4C4C4;
    border-radius: 4px;
}

/* nominated product */
.nominatedProductHeader {
    padding-bottom: 1rem;
}

.nominatedProduct {
    padding-top: 0;
}

.nominatedProduct input {
    width: 40%;
  /* border: 1px solid #C4C4C4; */
    padding: 10px;
}

/* case study section */

.caseStudySummarizeSection textarea {
    width: 100%;
    height: 150px;
    padding: 10px;
    /* border: none; */
}

.caseStudySummarizeFirstSection textarea,
.caseStudySummarizeFourthSection textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    border: 1px solid #C4C4C4;
    border-radius: 4px;
}

.caseStudySummarizeFirstSection,
.caseStudySummarizeThirdSection,
.caseStudySummarizeFourthSection {
    padding: 1rem 0;
}

.caseStudySummarizeSecondSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.caseStudySummarizeSecondSection input {
    padding: 10px;
    /* border: none; */
}

.caseStudySummarizeThirdSection div {
    padding: 0.5rem 0;
}

/* social media section */

.socialMediaFirstSection,
.socialMediaSecondSection,
.socialMediaThirdSection,
.socialMediaFourthSection {
    display: flex;
    padding: 1rem 0;
    align-items: center;
    /* width: 35%; */
    /* justify-content: space-between; */
}

.socialMediaFirstSection p,
.socialMediaSecondSection p,
.socialMediaThirdSection p,
.socialMediaFourthSection p,
.socialMediaFifthSection p,
.socialMediaSixthSection p {
    margin: 0;
}

.socialMediaFirstSection input,
.socialMediaSecondSection input,
.socialMediaThirdSection input,
.socialMediaFourthSection input,
.socialMediaFifthSection input,
.socialMediaSixthSection input {
    /* margin-left: 2rem; */
    /* border: none; */
    padding: 10px;
    margin-right: 2rem;
}

.socialMediaFifthSection,
.socialMediaSixthSection {
    display: flex;
    padding: 1rem 0;
    align-items: center;
    /* width: 63%; */
    /* justify-content: space-between; */
}

.socialMediaSixthSection p,
.socialMediaFifthSection p,
.socialMediaFirstSection p,
.socialMediaFourthSection p,
.socialMediaThirdSection p,
.socialMediaSecondSection p {
    width: 110px;
}

/* attach documents section */

.nominationAttachDocumentsFirstSection p,
.nominationAttachDocumentsSecondSection p {
    padding: 0;
    margin: 0;
    color: #4a4a4a;
}

.nominationAttachDocumentsFirstSection {
    padding: 1rem 0;
}

.nominationAttachDocumentsSecondSection {
    display: flex;
    width: 50%;
    justify-content: space-between;
    align-items: center;
}

.nominationAttachDocumentsSecondSection input {
    width: 80%;
    /* margin-left: 2rem; */
    padding: 10px;
    /* border: none; */
}

/* additional documents */
.nominationAdditionlDocumentsSectionHeader {
    font-size: 18px;
    font-weight: 700;
    font-family: sans-serif;
}

.nominationAdditionlDocumentsSection p {
    padding: 0;
    margin: 0;
    padding-top: 1rem;
}

.nominationAdditionlDocumentsSection input {
    width: 40%;
    /* border: none; */
    padding: 10px;
    margin-top: 2rem;
}

/* submit button section */
.submitNominationDetails {
    color: white;
    background-color: #283464;
    padding: 1rem 30px;
    border-radius: 3rem !important;
    border: none;
    cursor: pointer;
    margin: 0;
}

/* date */
input[type="date"]::before {
    color: lightgray;
    content: attr(placeholder);
}

input[type="date"] {
    color: #ffffff;
}

input[type="date"]:focus,
input[type="date"]:valid {
    color: black;
}

input[type="date"]:focus::before,
input[type="date"]:valid::before {
    content: "" !important;
}

/* .nominationSection input:focus,
.nominationSection textarea:focus,
.stateAndDateSection select:focus {
    outline-color: white;
} */

.disclaimer{
    padding-top: 2rem !important;
}

/* responsive for mobile section */


@media all and (max-width:500px) {

    .nameOfOrganizationSection,
    .corporateOfficeAddressSection,
    .stateAndDateSection,
    .websiteAndContactSection,
    .contactNameAndPositionSection,
    .contactIdAndEmailSection,
    .caseStudySummarizeSecondSection,
    .socialMediaFirstSection,
    .socialMediaSecondSection,
    .socialMediaThirdSection,
    .socialMediaFourthSection,
    .socialMediaFifthSection,
    .socialMediaSixthSection {
        flex-direction: column;
    }

    .nameOfOrganizationFirstSection,
    .nameOfOrganizationSecondSection,
    .corporateOfficeAddressFirstSection,
    .corporateOfficeAddressSecondSection,
    .stateAndDateFirstSection,
    .stateAndDateSecondSection,
    .websiteAndContactFirstSection,
    .websiteAndContactSecondSection,
    .contactNameAndPositionFirstSection,
    .contactNameAndPositionSecondSection,
    .contactIdAndEmailFirstSection,
    .contactIdAndEmailSecondSection {
        width: 100%;
    }

    .socialMediaSixthSection p,
    .socialMediaFifthSection p,
    .socialMediaFirstSection p,
    .socialMediaFourthSection p,
    .socialMediaThirdSection p,
    .socialMediaSecondSection p {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .nominationSocialMediaSectionHeader,
    .socialMediaSixthSection,
    .socialMediaFifthSection,
    .socialMediaFirstSection,
    .socialMediaFourthSection,
    .socialMediaThirdSection,
    .socialMediaSecondSection,
    .nominationCompanyInformationSection,
    .nominationContactDetailsApplicantSection,
    .nominationCaseStudySection,
    .nominationAttachDocumentsSection,
    .nominationAttachDocumentsSecondSection,
    .nominationAdditionlDocumentsSection,
    .nominationAdditionlDocumentsSection input {
        width: 100%;
        text-align: center;
    }

    .nominationSocialMediaSection,
    .nominationCompanyInformationSection,
    .nominationContactDetailsApplicantSection,
    .nominationCaseStudySection,
    .nominationAttachDocumentsSection,
    .nominationAdditionlDocumentsSection,
    .nominatedProduct {
        padding: 1rem 2rem;
    }
    .nominationSocialMediaSection p{
        text-align: center;
    }

    .socialMediaSixthSection,
    .socialMediaFifthSection,
    .socialMediaFirstSection,
    .socialMediaFourthSection,
    .socialMediaThirdSection,
    .socialMediaSecondSection {
        align-items: normal;
    }

    .socialMediaFirstSection input,
    .socialMediaSecondSection input,
    .socialMediaThirdSection input,
    .socialMediaFourthSection input,
    .socialMediaFifthSection input,
    .socialMediaSixthSection input {
        margin: 0;
    }

    .socialMediaFifthSection input,
    .socialMediaSixthSection input {
        margin-bottom: 1rem;
    }

    .openAllIndustriesFirstSection {
        margin: 0;
        margin-top: 1rem;
    }

    .nominatedProduct {
        padding-top: 0;
    }
    .nominatedProduct input{
        width: 100%;
    }
    .nominationAttachDocumentsFirstSection{
        text-align: left;
        padding-bottom: 2rem;
    }

    .faqDropDownBox{
        margin: 1rem;
        text-align: left;
        margin-bottom: 0;
    }
    .faqHeader{
        padding: 0;
        text-align: left;
    }
    .faqDropDown{
        padding: 1rem;
    }
}