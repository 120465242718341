.nominationAwardsFirstSection {
    background-color: #283464;
    color: white;
    padding: 2rem 0;

}

.nominationAwardsFirstSectionHeader {
    font-size: 26px;
    font-family: sans-serif;
    font-weight: 700;
}

.nominationAwardsFirstSection p {
    margin: 0;
    padding: 1rem 5%;
    font-size: 16px;
}

/* categories section */

.nominationAwardsSecondSection {
    padding: 2rem;
}

.nominationAwardsSecondSectionHeader {
    font-size: 32px;
    font-weight: 700;
    font-family: sans-serif;
    padding-bottom: 2rem;
}

.nominationAwardsSecondSectionMenu {
    display: flex;
    /* justify-content: space-evenly; */
    padding: 0rem 10%;
}

.sustainabilityDisclaimer {
    background-color: #d9f4ff;
    padding: 10px;
    font-size: 22px;
    margin: 0rem 10%;
    color: #555;
}

.awardCategory {
    width: 25%;
    background-color: #283464;
    color: white;
    margin-right: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0.5rem;
    cursor: pointer;
}


.awardsSection {
    display: flex;
    padding: 1rem 10%;
    padding-top: 0;
    justify-content: space-between;
}

.awardsImage img {
    width: 100px;
    height: 100px;
    cursor: pointer;
}

.awardsImage {
    padding: 2rem;
    display: flex;
    justify-content: space-evenly;
    margin: 1rem 10%;
    justify-content: space-between;
}

.awardsBackGroundColor{
    position: absolute;
    background-color: lightgray;
    height: 120px;
    width: 76%;
    margin-top: -150px;
    z-index: -2;
    left: 12%;
}

.awardsOver,
.awardsUnder {
    width: 48.5%;
    border: 2px solid lightgray;
    padding: 2%;
}

.awardsOverHeader,
.awardsUnderHeader {
    font-size: 24px;
    font-weight: 500;
    font-family: sans-serif;
}

.awardsOver p,
.awardsUnder p {
    margin: 0;
    font-size: 17px;
    padding: 1rem;
}

.nominationAwardsSecondSectionMenuData {
    padding-top: 2rem;
}

.nominationAwardsSecondSectionMenuData button {
    background-color: #283464;
    color: white;
    border-radius: 2rem;
    padding: 10px 30px;
    border: none;
    cursor: pointer;
}

/* tried love section */
.triedLoveNominateSection,
.sustainabilityNominationSection {
    width: 80%;
    border: 2px solid lightgray;
    padding: 2%;
    margin: 1rem 10%;
}

.triedLoveNominateSection p {
    margin: 0;
    font-size: 17px;
    padding: 1rem;
}

/* sustainability section */
.sustainabilityNominationSectionHeader {
    font-size: 24px;
    font-weight: 500;
    font-family: sans-serif;
}

.selectedCategory {
    background-color: none;
    background-color: #5bc5f5;
    color: #000;
}

/* jury awards list section */

.juryListSection {
    padding: 2rem 12%;
}

.juryListMainSection {
    display: flex;
    /* justify-content: space-evenly; */
    /* justify-content: center; */
    overflow-x: scroll;
}

.juryAwardClaimerSection {
    min-width: 19.8%;
    max-width: 19.8%;
    cursor: pointer;
    margin-right: 0.5rem;
}

.juryAwardClaimerSection p {
    margin: 0;
    padding: 0.5rem;
    font-size: 0.875rem;
}

.juryListMainSection img {
    width: 100%
}

.juryListSocialAccounts {
    display: flex;
    justify-content: center;
}

.juryListSocialAccounts * {
    margin: 2px 5px;
    /* color: white; */
    cursor: pointer;
    color: #859caa;
}

.juryAwardClaimerData {
    padding: 0.5rem 0;
    background-color: lightgray;
    cursor: pointer;
    height: 110px;
}

.juryListName {
    font-size: 18px;
    text-transform: uppercase;
}

.juryAwardClaimerData:hover {
    background-color: #0f1628;
    color: white;
}


.mobile {
    display: none;
}

.forbesSectionSlider .carousel-control-prev-icon,.forbesSectionSlider .carousel-control-next-icon,.forbesSectionSlider .carousel-dark .carousel-indicators [data-bs-target]{
    display: none;
}
.nominationButton{
    cursor:default !important;
}
/* responsive for mobile */

@media all and (max-width:500px) {
    .juryListMainSection{
        justify-content: space-between;
    }
    .nominationAwardsFirstSection p {
        padding: 1rem;
    }

    .nominationAwardsSecondSectionMenu {
        padding: 0;
        flex-direction: column;
    }

    .awardCategory {
        width: 100%;
        margin: 2px 0;
        font-size: 20px;
    }
    .awardsImage{
        /* display: flex; */
        /* flex-direction: row; */
        flex-wrap: wrap;
        padding: 0;
        margin: 0;
    }
    /* .awardsCategories{
        width: 50%;
    } */
    

    .triedLoveNominateSection,
    .sustainabilityNominationSection {
        margin: 0;
        width: 100%;
        padding: 1rem 0;
    }

    .awardsSection {
        flex-direction: column;
        padding: 0;
    }

    .awardsOver,
    .awardsUnder {
        width: 100%;
        margin-bottom: 1rem;
        padding: 1rem 0;
    }

    .awardsOverHeader,
    .awardsUnderHeader {
        font-size: 20px;
    }

    .awardsOver p,
    .awardsUnder p {
        font-size: 18px;
        padding: 0.5rem;
    }

    .triedLoveNominateSection p {
        font-size: 18px;
    }

    .nominationAwardsSecondSectionHeader {
        font-size: 24px;
        padding-bottom: 1rem;
    }

    .nominationAwardsSecondSection {
        padding: 1rem;
    }

    .nominationAwardsFirstSection {
        padding: 1rem 0;
    }

    .juryListSection {
        padding: 1rem;
        overflow-x: scroll;
    }

    .juryAwardClaimerSection {
        min-width: 200px;
        max-width: 200px;
        margin-right: 0.5rem;
    }

    .juryAwardClaimerData {
        width: 200px;
        padding: 5px;
    }

    .juryAwardClaimerSection p {
        padding: 5px;
    }

    .nominationAwardsSecondSectionMenuData {
        display: none;
    }

    .mobile {
        display: block;
        margin: 0.5em 0;
        transition: all 0.3s;
    }

    .mobile button {
        background-color: #283464;
        color: white;
        border-radius: 2rem;
        padding: 10px 30px;
        border: none;
        cursor: pointer;
    }

    /* .nominationAwardsSecondSectionMenu {
        height: auto;
        transition: all 0.3s;
    } */

    .sustainabilityDisclaimer,
    .sustainabilityDisclaimer {
        margin: 0;
        font-size: 18px;
    }

}